// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Grid,
//   Container,
//   TextField,
//   Button,
//   Typography,
//   LinearProgress,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   FormLabel,
//   Drawer,
//   IconButton,
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Switch,
//   ClickAwayListener
// } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';

// const App = () => {
//   const [url, setUrl] = useState('');
//   const [changefreq, setChangefreq] = useState('daily');
//   const [priority, setPriority] = useState(0.8);
//   const [progress, setProgress] = useState({
//     addedPages: 0,
//     status: 'idle',
//     error: null,
//     pages: [],
//     ingoingLinks: {},
//     outgoingLinks: {},
//     brokenLinks: []
//   });
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [iconPositionRight, setIconPositionRight] = useState(true);

//   const handleStart = () => {
//     axios.post('http://localhost:4000/start-sitemap', { url, changefreq, priority })
//       .then(() => {
//         setProgress(prevProgress => ({ ...prevProgress, status: 'running' }));
//       })
//       .catch(err => console.error(err));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress.status === 'running') {
//         axios.get('http://localhost:4000/sitemap-progress')
//           .then(response => setProgress(response.data))
//           .catch(err => console.error(err));
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress.status]);

//   const handleDownload = () => {
//     window.location.href = 'http://localhost:4000/download-sitemap';
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleDrawerClose = () => {
//     setDrawerOpen(false);
//   };

//   const handleIconPositionToggle = () => {
//     setIconPositionRight(!iconPositionRight);
//   };

//   return (
//     <Container maxWidth="lg">
//       <CssBaseline />
//       <AppBar position="fixed">
//         <Toolbar>
//           <Typography variant="h6" noWrap>
//             Sitemap Generator
//           </Typography>
//           <div style={{ marginLeft: 'auto' }}>
//             <IconButton
//               color="inherit"
//               onClick={handleDrawerToggle}
//               style={{ marginRight: iconPositionRight ? '16px' : 'auto' }}
//             >
//               <SettingsIcon />
//             </IconButton>
//           </div>
//         </Toolbar>
//       </AppBar>
//       <Toolbar />
//       <Box my={4}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Website URL"
//               value={url}
//               onChange={e => setUrl(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button variant="contained" color="primary" onClick={handleStart} disabled={progress.status === 'running'}>
//               Start Sitemap Generation
//             </Button>
//           </Grid>
//           <Grid item xs={12}>
//             {progress.status === 'running' && (
//               <Box my={2}>
//                 <LinearProgress />
//                 <Typography variant="body2">Pages Added: {progress.addedPages}</Typography>
//               </Box>
//             )}
//             {progress.status === 'done' && (
//               <Box my={2}>
//                 <Typography variant="h6">Sitemap Generation Completed</Typography>
//                 <Button variant="contained" color="secondary" onClick={handleDownload}>
//                   Download Sitemap
//                 </Button>
//               </Box>
//             )}
//             {progress.status === 'error' && (
//               <Box my={2}>
//                 <Typography color="error">Error: {progress.error}</Typography>
//               </Box>
//             )}
//           </Grid>
//           <Grid item xs={12}>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Page URL</TableCell>
//                     <TableCell>Outgoing Links</TableCell>
//                     <TableCell>Ingoing Links</TableCell>
//                     <TableCell>Broken Links</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                  {progress.pages.map((page, index) => (
//     <TableRow key={index}>
//       <TableCell>{page.url}</TableCell>
//       <TableCell>
//         {progress.outgoingLinks[page.url]?.map((link, idx) => (
//           <div key={idx}>{link}</div>
//         ))}
//       </TableCell>
//       <TableCell>
//         {progress.ingoingLinks[page.url]?.map((link, idx) => (
//           <div key={idx}>{link}</div>
//         ))}
//       </TableCell>
//       <TableCell>
//         {progress.brokenLinks.includes(page.url) ? 'Broken' : ''}
//       </TableCell>
//     </TableRow>
// ))}s

//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//         </Grid>
//       </Box>

//       <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
//         <ClickAwayListener onClickAway={handleDrawerClose}>
//           <Box width={300} p={2}>
//             <FormControl component="fieldset">
//               <FormLabel component="legend">Settings</FormLabel>
//               <RadioGroup
//                 value={changefreq}
//                 onChange={e => setChangefreq(e.target.value)}
//               >
//                 <FormControlLabel value="daily" control={<Radio />} label="Daily" />
//                 <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
//                 <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
//               </RadioGroup>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 label="Priority"
//                 type="number"
//                 inputProps={{ step: 0.1, min: 0.0, max: 1.0 }}
//                 value={priority}
//                 onChange={e => setPriority(parseFloat(e.target.value))}
//               />
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={iconPositionRight}
//                     onChange={handleIconPositionToggle}
//                     name="iconPosition"
//                   />
//                 }
//                 label="Settings Icon Position Right"
//               />
//             </FormControl>
//           </Box>
//         </ClickAwayListener>
//       </Drawer>
//     </Container>
//   );
// };

// export default App;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Grid,
//   Container,
//   TextField,
//   Button,
//   Typography,
//   LinearProgress,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   FormLabel,
//   Drawer,
//   IconButton,
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Switch,
//   ClickAwayListener
// } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';

// const App = () => {
//   const [url, setUrl] = useState('');
//   const [changefreq, setChangefreq] = useState('daily');
//   const [priority, setPriority] = useState(0.8);
//   const [progress, setProgress] = useState({
//     addedPages: 0,
//     status: 'idle',
//     error: null,
//     pages: [],
//     ingoingLinks: {}, // Ensure default is an empty object
//     outgoingLinks: {}, // Ensure default is an empty object
//     brokenLinks: [] // Ensure default is an empty array
//   });
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [iconPositionRight, setIconPositionRight] = useState(true);

//   const handleStart = () => {
//     axios.post('http://localhost:4000/start-sitemap', { url, changefreq, priority })
//       .then(() => {
//         setProgress(prevProgress => ({ ...prevProgress, status: 'running' }));
//       })
//       .catch(err => console.error(err));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress.status === 'running') {
//         axios.get('http://localhost:4000/sitemap-progress')
//           .then(response => setProgress(response.data))
//           .catch(err => console.error(err));
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress.status]);

//   const handleDownload = () => {
//     window.location.href = 'http://localhost:4000/download-sitemap';
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleApplyChanges = () => {
//     setDrawerOpen(false); // Close the drawer
//   };

//   const handleIconPositionChange = () => {
//     setIconPositionRight(!iconPositionRight);
//   };

//   return (
//     <div>
//       <CssBaseline />
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="settings"
//             onClick={handleDrawerToggle}
//             style={{ marginLeft: iconPositionRight ? 'auto' : '16px', marginRight: iconPositionRight ? '16px' : 'auto' }}
//           >
//             <SettingsIcon />
//           </IconButton>
//           <Typography variant="h6">
//             Sitemap Generator
//           </Typography>
//           <Switch
//             checked={iconPositionRight}
//             onChange={handleIconPositionChange}
//             inputProps={{ 'aria-label': 'toggle icon position' }}
//             style={{ marginLeft: 'auto' }}
//           />
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={() => {}}
//       >
//         <ClickAwayListener onClickAway={() => {}}>
//           <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             p={2}
//           >
//             <Typography variant="h6" gutterBottom>
//               Settings
//             </Typography>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Change Frequency</FormLabel>
//               <RadioGroup
//                 row
//                 value={changefreq}
//                 onChange={(e) => setChangefreq(e.target.value)}
//               >
//                 <FormControlLabel value="daily" control={<Radio />} label="Daily" />
//                 <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
//                 <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
//                 <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
//               </RadioGroup>
//             </FormControl>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Priority</FormLabel>
//               <RadioGroup
//                 row
//                 value={priority}
//                 onChange={(e) => setPriority(parseFloat(e.target.value))}
//               >
//                 <FormControlLabel value={1.0000} control={<Radio />} label="1.0" />
//                 <FormControlLabel value={0.8000} control={<Radio />} label="0.8" />
//                 <FormControlLabel value={0.5000} control={<Radio />} label="0.5" />
//                 <FormControlLabel value={0.3000} control={<Radio />} label="0.3" />
//               </RadioGroup>
//             </FormControl>
//             <Box mt={2}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 onClick={handleApplyChanges}
//               >
//                 Apply Changes
//               </Button>
//             </Box>
//           </Box>
//         </ClickAwayListener>
//       </Drawer>

//       <Container maxWidth="sm">
//         <Box mt={4} mb={2}>
//           <TextField
//             fullWidth
//             label="Website URL"
//             variant="outlined"
//             value={url}
//             onChange={(e) => setUrl(e.target.value)}
//             margin="normal"
//           />
//           <Button
//             fullWidth
//             variant="contained"
//             color="primary"
//             onClick={handleStart}
//             disabled={progress.status === 'running'}
//           >
//             Start Generating Sitemap
//           </Button>
//         </Box>
//         {progress.status === 'running' && (
//           <Box mt={4}>
//             <Typography variant="body1">Pages added to sitemap: {progress.addedPages}</Typography>
//             <LinearProgress />
//           </Box>
//         )}
//         {progress.pages.length > 0 && (
//           <>
//             <Box mt={2} mb={1}>
//               <Typography variant="body1">Total Pages Added: {progress.addedPages}</Typography>
//             </Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <TableContainer component={Paper} style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
//                   <Table stickyHeader aria-label="pages table" style={{ width: '100%' }}>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Page URL</TableCell>
//                         <TableCell align="right">Date/Time Added</TableCell>
//                         <TableCell align="right">Ingoing Links</TableCell>
//                         <TableCell align="right">Outgoing Links</TableCell>
//                         <TableCell align="right">Broken Links</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {progress.pages.map((page, index) => (
//                         <TableRow key={index}>
//                           <TableCell component="th" scope="row">
//                             {page.url}
//                           </TableCell>
//                           <TableCell align="right">{page.dateTime}</TableCell>
//                           <TableCell align="right">
//                             {progress.ingoingLinks && progress.ingoingLinks[page.url] ? progress.ingoingLinks[page.url].length : 0}
//                           </TableCell>
//                           <TableCell align="right">
//                             {progress.outgoingLinks && progress.outgoingLinks[page.url] ? progress.outgoingLinks[page.url].length : 0}
//                           </TableCell>
//                           <TableCell align="right">
//                             {progress.brokenLinks && progress.brokenLinks.includes(page.url) ? 1 : 0}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               </Grid>
//             </Grid>
//           </>
//         )}
//         {progress.status === 'done' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="green">Sitemap generation completed!</Typography>
//             <Button
//               fullWidth
//               variant="contained"
//               color="secondary"
//               onClick={handleDownload}
//             >
//               Download Sitemap
//             </Button>
//           </Box>
//         )}
//         {progress.status === 'error' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="red">Error: {progress.error}</Typography>
//           </Box>
//         )}
//       </Container>
//     </div>
//   );
// };

// export default App;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Grid,
//   Container,
//   TextField,
//   Button,
//   Typography,
//   LinearProgress,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   FormLabel,
//   Drawer,
//   IconButton,
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Switch,
//   ClickAwayListener
// } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';

// const App = () => {
//   const [url, setUrl] = useState('');
//   const [changefreq, setChangefreq] = useState('daily');
//   const [priority, setPriority] = useState(0.8);
//   const [excludedUrls, setExcludedUrls] = useState('');
//   const [crawlButExclude, setCrawlButExclude] = useState('');
//   const [progress, setProgress] = useState({
//     addedPages: 0,
//     status: 'idle',
//     error: null,
//     pages: [],
//     ingoingLinks: {},
//     outgoingLinks: {},
//     brokenLinks: []
//   });
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [iconPositionRight, setIconPositionRight] = useState(true);

//   const handleStart = () => {
//     axios.post('http://localhost:4000/start-sitemap', { 
//       url, 
//       changefreq, 
//       priority, 
//       excludedUrls: excludedUrls.split('\n').map(line => line.trim()).filter(line => line),
//       crawlButExclude: crawlButExclude.split('\n').map(line => line.trim()).filter(line => line)
//     })
//     .then(() => {
//       setProgress(prevProgress => ({ ...prevProgress, status: 'running' }));
//     })
//     .catch(err => console.error(err));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress.status === 'running') {
//         axios.get('http://localhost:4000/sitemap-progress')
//           .then(response => setProgress(response.data))
//           .catch(err => console.error(err));
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress.status]);

//   const handleDownload = () => {
//     window.location.href = 'http://localhost:4000/download-sitemap';
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleApplyChanges = () => {
//     setDrawerOpen(false); // Close the drawer
//   };

//   const handleIconPositionChange = () => {
//     setIconPositionRight(!iconPositionRight);
//   };

//   return (
//     <div>
//       <CssBaseline />
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="settings"
//             onClick={handleDrawerToggle}
//             style={{ marginLeft: iconPositionRight ? 'auto' : '16px', marginRight: iconPositionRight ? '16px' : 'auto' }}
//           >
//             <SettingsIcon />
//           </IconButton>
//           <Typography variant="h6">
//             Sitemap Generator
//           </Typography>
//           <Switch
//             checked={iconPositionRight}
//             onChange={handleIconPositionChange}
//             inputProps={{ 'aria-label': 'toggle icon position' }}
//             style={{ marginLeft: 'auto' }}
//           />
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={() => {}}
//       >
//         <ClickAwayListener onClickAway={() => {}}>
//           <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             p={2}
//           >
//             <Typography variant="h6" gutterBottom>
//               Settings
//             </Typography>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Change Frequency</FormLabel>
//               <RadioGroup
//                 row
//                 value={changefreq}
//                 onChange={(e) => setChangefreq(e.target.value)}
//               >
//                 <FormControlLabel value="daily" control={<Radio />} label="Daily" />
//                 <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
//                 <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
//                 <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
//               </RadioGroup>
//             </FormControl>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Priority</FormLabel>
//               <RadioGroup
//                 row
//                 value={priority}
//                 onChange={(e) => setPriority(parseFloat(e.target.value))}
//               >
//                 <FormControlLabel value={1.0000} control={<Radio />} label="1.0" />
//                 <FormControlLabel value={0.8000} control={<Radio />} label="0.8" />
//                 <FormControlLabel value={0.5000} control={<Radio />} label="0.5" />
//                 <FormControlLabel value={0.3000} control={<Radio />} label="0.3" />
//               </RadioGroup>
//             </FormControl>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Excluded URLs (One per line)</FormLabel>
//               <TextField
//                 multiline
//                 rows={4}
//                 value={excludedUrls}
//                 onChange={(e) => setExcludedUrls(e.target.value)}
//                 variant="outlined"
//                 fullWidth
//               />
//             </FormControl>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Crawl but Do Not Include (One per line)</FormLabel>
//               <TextField
//                 multiline
//                 rows={4}
//                 value={crawlButExclude}
//                 onChange={(e) => setCrawlButExclude(e.target.value)}
//                 variant="outlined"
//                 fullWidth
//               />
//             </FormControl>
//             <Box mt={2}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 onClick={handleApplyChanges}
//               >
//                 Apply Changes
//               </Button>
//             </Box>
//           </Box>
//         </ClickAwayListener>
//       </Drawer>

//       <Container maxWidth="sm">
//         <Box mt={4} mb={2}>
//           <TextField
//             fullWidth
//             label="Website URL"
//             variant="outlined"
//             value={url}
//             onChange={(e) => setUrl(e.target.value)}
//             margin="normal"
//           />
//           <Button
//             fullWidth
//             variant="contained"
//             color="primary"
//             onClick={handleStart}
//             disabled={progress.status === 'running'}
//           >
//             Start Generating Sitemap
//           </Button>
//         </Box>
//         {progress.status === 'running' && (
//           <Box mt={4}>
//             <Typography variant="body1">Pages added to sitemap: {progress.addedPages}</Typography>
//             <LinearProgress />
//           </Box>
//         )}
//         {progress.pages.length > 0 && (
//           <>
//             <Box mt={2} mb={1}>
//               <Typography variant="body1">Total Pages Added: {progress.addedPages}</Typography>
//             </Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <TableContainer component={Paper} style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
//                   <Table stickyHeader aria-label="pages table" style={{ width: '100%' }}>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Page URL</TableCell>
//                         <TableCell align="right">Date/Time Added</TableCell>
//                         <TableCell align="right">In Links</TableCell>
//                         <TableCell align="right">Out Links</TableCell>
//                         <TableCell align="right">Broken Links</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {progress.pages.map((page, index) => (
//                         <TableRow key={index}>
//                           <TableCell component="th" scope="row">
//                             {page.url}
//                           </TableCell>
//                           <TableCell align="right">{page.dateTime}</TableCell>
//                           <TableCell align="right">
//                             {progress.ingoingLinks && progress.ingoingLinks[page.url] ? progress.ingoingLinks[page.url].length : 0}
//                           </TableCell>
//                           <TableCell align="right">
//                             {progress.outgoingLinks && progress.outgoingLinks[page.url] ? progress.outgoingLinks[page.url].length : 0}
//                           </TableCell>
//                           <TableCell align="right">
//                             {progress.brokenLinks && progress.brokenLinks.includes(page.url) ? 1 : 0}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               </Grid>
//             </Grid>
//           </>
//         )}
//         {progress.status === 'done' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="green">Sitemap generation completed!</Typography>
//             <Button
//               fullWidth
//               variant="contained"
//               color="secondary"
//               onClick={handleDownload}
//             >
//               Download Sitemap
//             </Button>
//           </Box>
//         )}
//         {progress.status === 'error' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="red">Error: {progress.error}</Typography>
//           </Box>
//         )}
//       </Container>
//     </div>
//   );
// };

// export default App;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Grid,
//   Container,
//   TextField,
//   Button,
//   Typography,
//   LinearProgress,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   FormLabel,
//   Drawer,
//   IconButton,
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Switch,
//   ClickAwayListener
// } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';

// const App = () => {
//   const [url, setUrl] = useState('');
//   const [changefreq, setChangefreq] = useState('daily');
//   const [priority, setPriority] = useState(0.8);
//   const [progress, setProgress] = useState({
//     addedPages: 0,
//     status: 'idle',
//     error: null,
//     pages: [],
//   });
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [iconPositionRight, setIconPositionRight] = useState(true);

//   const handleStart = () => {
//     axios.post('http://localhost:4000/start-sitemap', { url, changefreq, priority })
//       .then(() => {
//         setProgress({ ...progress, status: 'running' });
//       })
//       .catch(err => console.error(err));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress.status === 'running') {
//         axios.get('http://localhost:4000/sitemap-progress')
//           .then(response => setProgress(response.data))
//           .catch(err => console.error(err));
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress.status]);

//   const handleDownload = () => {
//     window.location.href = 'http://localhost:4000/download-sitemap';
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleApplyChanges = () => {
//     setDrawerOpen(false); // Close the drawer
//   };

//   const handleIconPositionChange = () => {
//     setIconPositionRight(!iconPositionRight);
//   };

//   return (
//     <div>
//       <CssBaseline />
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="settings"
//             onClick={handleDrawerToggle}
//             style={{ marginLeft: iconPositionRight ? 'auto' : '16px', marginRight: iconPositionRight ? '16px' : 'auto' }}
//           >
//             <SettingsIcon />
//           </IconButton>
//           <Typography variant="h6">
//             Sitemap Generator
//           </Typography>
//           <Switch
//             checked={iconPositionRight}
//             onChange={handleIconPositionChange}
//             inputProps={{ 'aria-label': 'toggle icon position' }}
//             style={{ marginLeft: 'auto' }}
//           />
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={() => {}}
//       >
//         <ClickAwayListener onClickAway={() => {}}>
//           <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             p={2}
//           >
//             <Typography variant="h6" gutterBottom>
//               Settings
//             </Typography>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Change Frequency</FormLabel>
//               <RadioGroup
//                 row
//                 value={changefreq}
//                 onChange={(e) => setChangefreq(e.target.value)}
//               >
//                 <FormControlLabel value="daily" control={<Radio />} label="Daily" />
//                 <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
//                 <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
//                 <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
//               </RadioGroup>
//             </FormControl>
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Priority</FormLabel>
//               <RadioGroup
//                 row
//                 value={priority}
//                 onChange={(e) => setPriority(parseFloat(e.target.value))}
//               >
//                 <FormControlLabel value={1.0000} control={<Radio />} label="1.0" />
//                 <FormControlLabel value={0.8000} control={<Radio />} label="0.8" />
//                 <FormControlLabel value={0.5000} control={<Radio />} label="0.5" />
//                 <FormControlLabel value={0.3000} control={<Radio />} label="0.3" />
//               </RadioGroup>
//             </FormControl>
//             <Box mt={2}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 onClick={handleApplyChanges}
//               >
//                 Apply Changes
//               </Button>
//             </Box>
//           </Box>
//         </ClickAwayListener>
//       </Drawer>

//       <Container maxWidth="sm">
//         <Box mt={4} mb={2}>
//           <TextField
//             fullWidth
//             label="Website URL"
//             variant="outlined"
//             value={url}
//             onChange={(e) => setUrl(e.target.value)}
//             margin="normal"
//           />
//           <Button
//             fullWidth
//             variant="contained"
//             color="primary"
//             onClick={handleStart}
//             disabled={progress.status === 'running'}
//           >
//             Start Generating Sitemap
//           </Button>
//         </Box>
//         {progress.status === 'running' && (
//           <Box mt={4}>
//             <Typography variant="body1">Pages added to sitemap: {progress.addedPages}</Typography>
//             <LinearProgress />
//           </Box>
//         )}
//         {progress.pages.length > 0 && (
//           <>
//             <Box mt={2} mb={1}>
//               <Typography variant="body1">Total Pages Added: {progress.addedPages}</Typography>
//             </Box>
//             <Grid container>
//               <Grid item xs={12}>
//                 <TableContainer component={Paper} style={{ width: '100%', maxHeight: '400px', overflowY: 'auto' }}>
//                   <Table stickyHeader aria-label="pages table" style={{ width: '100%' }}>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell>Page URL</TableCell>
//                         <TableCell align="right">Date/Time Added</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {progress.pages.map((page, index) => (
//                         <TableRow key={index}>
//                           <TableCell component="th" scope="row">
//                             {page.url}
//                           </TableCell>
//                           <TableCell align="right">{page.dateTime}</TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               </Grid>
//             </Grid>
//           </>
//         )}
//         {progress.status === 'done' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="green">Sitemap generation completed!</Typography>
//             <Button
//               fullWidth
//               variant="contained"
//               color="secondary"
//               onClick={handleDownload}
//             >
//               Download Sitemap
//             </Button>
//           </Box>
//         )}
//         {progress.status === 'error' && (
//           <Box mt={4}>
//             <Typography variant="body1" color="red">Error: {progress.error}</Typography>
//           </Box>
//         )}
//       </Container>
//     </div>
//   );
// };

// export default App;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Grid,
//   Container,
//   TextField,
//   Button,
//   Typography,
//   LinearProgress,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   FormLabel,
//   Drawer,
//   IconButton,
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Switch,
//   ClickAwayListener,
//   TextareaAutosize // Import TextareaAutosize for the exclude paths text area
// } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';

// const App = () => {
//   const [url, setUrl] = useState('');
//   const [changefreq, setChangefreq] = useState('daily');
//   const [priority, setPriority] = useState(0.8);
//   const [excludePaths, setExcludePaths] = useState(''); // New state for exclude paths
//   const [progress, setProgress] = useState({
//     addedPages: 0,
//     status: 'idle',
//     error: null,
//     pages: [],
//   });
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [iconPositionRight, setIconPositionRight] = useState(true);

//   const handleStart = () => {
//     axios.post('http://localhost:4000/start-sitemap', {
//       url,
//       changefreq,
//       priority,
//       excludePaths: excludePaths.split('\n').map(path => path.trim()).filter(path => path)
//     })
//       .then(() => {
//         setProgress({ ...progress, status: 'running' });
//       })
//       .catch(err => console.error(err));
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress.status === 'running') {
//         axios.get('http://localhost:4000/sitemap-progress')
//           .then(response => setProgress(response.data))
//           .catch(err => console.error(err));
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress.status]);

//   const handleDownload = () => {
//     window.location.href = 'http://localhost:4000/download-sitemap';
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleApplyChanges = () => {
//     setDrawerOpen(false); // Close the drawer
//   };

//   const handleIconPositionChange = () => {
//     setIconPositionRight(!iconPositionRight);
//   };

//   return (
//     <div>
//       <CssBaseline />
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="settings"
//             onClick={handleDrawerToggle}
//             style={{ marginLeft: iconPositionRight ? 'auto' : '16px', marginRight: iconPositionRight ? '16px' : 'auto' }}
//           >
//             <SettingsIcon />
//           </IconButton>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
//         <Box p={3} width={300}>
//           <Typography variant="h6" gutterBottom>
//             Settings
//           </Typography>
//           <FormControl fullWidth margin="normal">
//             <FormLabel>Change Frequency</FormLabel>
//             <RadioGroup
//               value={changefreq}
//               onChange={(e) => setChangefreq(e.target.value)}
//             >
//               <FormControlLabel value="always" control={<Radio />} label="Always" />
//               <FormControlLabel value="hourly" control={<Radio />} label="Hourly" />
//               <FormControlLabel value="daily" control={<Radio />} label="Daily" />
//               <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
//               <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
//               <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
//               <FormControlLabel value="never" control={<Radio />} label="Never" />
//             </RadioGroup>
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <FormLabel>Priority</FormLabel>
//             <TextField
//               type="number"
//               value={priority}
//               onChange={(e) => setPriority(parseFloat(e.target.value))}
//               inputProps={{ step: 0.1, min: 0, max: 1 }}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <FormLabel>Exclude Paths</FormLabel>
//             <TextareaAutosize
//               minRows={3}
//               placeholder="Enter paths to exclude, one per line"
//               value={excludePaths}
//               onChange={(e) => setExcludePaths(e.target.value)}
//               style={{ width: '100%' }}
//             />
//           </FormControl>
//           <Button onClick={handleApplyChanges} variant="contained" color="primary" fullWidth>
//             Apply Changes
//           </Button>
//           <FormControl fullWidth margin="normal">
//             <FormLabel>Icon Position</FormLabel>
//             <Switch
//               checked={iconPositionRight}
//               onChange={handleIconPositionChange}
//               color="primary"
//             />
//           </FormControl>
//         </Box>
//       </Drawer>
//       <Container>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <TextField
//               label="Website URL"
//               fullWidth
//               value={url}
//               onChange={(e) => setUrl(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button onClick={handleStart} variant="contained" color="primary" fullWidth>
//               Start Sitemap Generation
//             </Button>
//           </Grid>
//           <Grid item xs={12}>
//             {progress.status === 'running' && (
//               <Box mt={2}>
//                 <Typography>Generating sitemap, please wait...</Typography>
//                 <LinearProgress />
//               </Box>
//             )}
//             {progress.status === 'done' && (
//               <Box mt={2}>
//                 <Typography>Sitemap generation complete!</Typography>
//                 <Button onClick={handleDownload} variant="contained" color="secondary">
//                   Download Sitemap
//                 </Button>
//               </Box>
//             )}
//             {progress.error && (
//               <Box mt={2}>
//                 <Typography color="error">Error: {progress.error}</Typography>
//               </Box>
//             )}
//           </Grid>
//         </Grid>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <TableContainer component={Paper} style={{ marginTop: '20px' }}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>URL</TableCell>
//                     <TableCell align="right">Timestamp</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {progress.pages.map((page, index) => (
//                     <TableRow key={index}>
//                       <TableCell component="th" scope="row">
//                         {page.url}
//                       </TableCell>
//                       <TableCell align="right">
//                         {new Date(page.dateTime).toLocaleString()}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//         </Grid>
//       </Container>
//     </div>
//   );
// };

// export default App;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  TextField,
  Button,
  Typography,
  LinearProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  CssBaseline,
  Switch,
  TextareaAutosize,
  TablePagination // Import TablePagination for pagination control
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const App = () => {
  const [url, setUrl] = useState('');
  const [changefreq, setChangefreq] = useState('daily');
  const [priority, setPriority] = useState(0.8);
  const [excludePaths, setExcludePaths] = useState('');
  const [progress, setProgress] = useState({
    addedPages: 0,
    status: 'idle',
    error: null,
    pages: [],
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [iconPositionRight, setIconPositionRight] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleStart = () => {
    axios.post('https://bsite.supportplus.app/start-sitemap', {
    // axios.post('http://localhost:4000/start-sitemap', {
      url,
      changefreq,
      priority,
      excludePaths: excludePaths.split('\n').map(path => path.trim()).filter(path => path)
    })
      .then(() => {
        setProgress({ ...progress, status: 'running' });
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress.status === 'running') {
        // axios.get('http://localhost:4000/sitemap-progress')
        axios.get('https://bsite.supportplus.app/sitemap-progress')
          .then(response => setProgress(response.data))
          .catch(err => console.error(err));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [progress.status]);

  const handleDownload = () => {
    window.location.href = 'https://bsite.supportplus.app/download-sitemap';
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleApplyChanges = () => {
    setDrawerOpen(false); // Close the drawer
  };

  const handleIconPositionChange = () => {
    setIconPositionRight(!iconPositionRight);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="settings"
            onClick={handleDrawerToggle}
            style={{ marginLeft: iconPositionRight ? 'auto' : '16px', marginRight: iconPositionRight ? '16px' : 'auto' }}
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box p={3} width={300}>
          <Typography variant="h6" gutterBottom>
            Settings
          </Typography>
          <FormControl fullWidth margin="normal">
            <FormLabel>Change Frequency</FormLabel>
            <RadioGroup
              value={changefreq}
              onChange={(e) => setChangefreq(e.target.value)}
            >
              <FormControlLabel value="always" control={<Radio />} label="Always" />
              <FormControlLabel value="hourly" control={<Radio />} label="Hourly" />
              <FormControlLabel value="daily" control={<Radio />} label="Daily" />
              <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
              <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
              <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
              <FormControlLabel value="never" control={<Radio />} label="Never" />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>Priority</FormLabel>
            <TextField
              type="number"
              value={priority}
              onChange={(e) => setPriority(parseFloat(e.target.value))}
              inputProps={{ step: 0.1, min: 0, max: 1 }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>Exclude Paths</FormLabel>
            <TextareaAutosize
              minRows={3}
              placeholder="Enter paths to exclude, one per line"
              value={excludePaths}
              onChange={(e) => setExcludePaths(e.target.value)}
              style={{ width: '100%' }}
            />
          </FormControl>
          <Button onClick={handleApplyChanges} variant="contained" color="primary" fullWidth>
            Apply Changes
          </Button>
          <FormControl fullWidth margin="normal">
            <FormLabel>Icon Position</FormLabel>
            <Switch
              checked={iconPositionRight}
              onChange={handleIconPositionChange}
              color="primary"
            />
          </FormControl>
        </Box>
      </Drawer>
      <Container className='' style={{marginTop:'40px'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              // label="Website URL"
              fullWidth
              placeholder='Enter Your Site Url'
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleStart} variant="contained" color="primary" fullWidth>
              Start Sitemap Generation
            </Button>
          </Grid>
          <Grid item xs={12}>
            {progress.status === 'running' && (
              <Box mt={2}>
                <Typography>Generating sitemap, please wait...</Typography>
                <LinearProgress />
              </Box>
            )}
            {progress.status === 'done' && (
              <Box mt={2}>
                <Typography>Sitemap generation complete!</Typography>
                <Button onClick={handleDownload} variant="contained" color="secondary">
                  Download Sitemap
                </Button>
              </Box>
            )}
            {progress.error && (
              <Box mt={2}>
                <Typography color="error">Error: {progress.error}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {progress.pages.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Typography variant="h6">Pages Added: {progress.addedPages}</Typography>
                <Typography variant="body2" color="textSecondary">Total URLs: {progress.pages.length}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>URL</strong></TableCell>
                      <TableCell align="right"><strong>Timestamp</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {progress.pages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((page, index) => (
                      <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                        <TableCell component="th" scope="row" style={{ padding: '12px' }}>
                          {page.url}
                        </TableCell>
                        <TableCell align="right" style={{ padding: '12px' }}>
                          {new Date(page.dateTime).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={progress.pages.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default App;
